export default class Accordion {
  _accordionToggles = null;

  constructor() {
    this._accordionToggles = document.querySelectorAll('.accordion__toggle');

    if (this._accordionToggles.length > 0) {
      for(let i in this._accordionToggles){
        if(this._accordionToggles.hasOwnProperty(i)){
          const isOpened = this._accordionToggles[i].getAttribute('data-opened');
          this._accordionToggles[i].addEventListener('click', this.handleAccordionLink);
          if (isOpened === '1') {
            this._accordionToggles[i].click();
          }
        }
      }
    }
  }

  handleAccordionLink(e) {
    e.preventDefault();
    e.stopPropagation();

    /*const previousOpened = document.querySelector('.accordion__toggle--active');
    if(previousOpened !== null && previousOpened !== this){
      previousOpened.classList.remove('accordion__toggle--active');
      previousOpened.nextElementSibling.style.height = 0;
    }*/

    const el = e.currentTarget;
    const content = el.parentNode.querySelector('.accordion__data');

    if(el.classList.contains('accordion__toggle--active')){
      content.classList.remove('accordion__data--visible');
      el.classList.remove('accordion__toggle--active');
    }else{
      content.classList.add('accordion__data--visible');
      el.classList.add('accordion__toggle--active');
    }
  }
}